import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import quoteIcon from '../icons/quote.svg';

const Section3 = ({}) => (
  <section className="container py-10 md:py-0 pb-12 lg:pb-4 text-center lg:mt-negative-4">
    <div className="md:flex md:justify-center">
      <div className="md:w-4/5 lg:w-8/12">
        <img src={quoteIcon} alt="Quote" className="w-20 lg:w-24 xl:w-24 mx-auto mb-8 lg:mb-12 block" />
        <blockquote className="leading-relaxed font-light text-xl">
          Die Zusammenarbeit mit Female Co-Founders war großartig. Die Erwartungen an
          die Veranstaltungen wurden qualitativ und quantitativ bezüglich der
          Teilnehmer*innenzahl übertroffen. Gerne wieder!

          <footer className="text-sm font-bold mt-8 md:pb-8">
            Julia Kümper, CEO VentureVilla Accelerator GmbH, Pitch Night in Hannover, November 2019
          </footer>
        </blockquote>
      </div>
    </div>
  </section>
)

Section3.propTypes = {
}

Section3.defaultProps = {
}

export default Section3
