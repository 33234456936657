import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import mobilePhoto from '../photos/b2b_mobile_bottom.png'
import photo from '../photos/b2b_bottom.png'

const Section4 = ({}) => (
  <div className="relative mb-16 lg:mt-20">
    <div className="md:rotated-bg absolute bg-pearl-lusta">
    </div>
    <div className="bg-pearl-lusta h-16 absolute w-full" style={ { bottom: "-4rem", zIndex: -1 } }></div>

    <div className="md:hidden">
      <img src={mobilePhoto} />
    </div>

    <div className="fc-header-wrapper b2b2 hidden md:block" style={ { bottom: "-4rem", zIndex: -1 } }>
      <div className="fc-header b2b2" style={{ backgroundImage: `url(${photo})` }}></div>
    </div>

    <div className="bg-pearl-lusta md:bg-transparent">
      <div className="container pt-8 pb-4 md:pt-12 md:pb-0 lg:pt-12  xl:pt-20 xl:pb-4 xl2:pt-42 xl2:pb-8 md:flex">
        <div className="md:w-6/12"></div>
        <div className="md:w-6/12">
          <div className="lg:text-lg tracking-wider text-gray-500 uppercase font-display font-bold mb-1 md:mb-4 text-gradient-green">#BetterFoundingTeams</div>
          <h1 className="text-3xl lg:text-5xl leading-none font-display font-bold">Mission</h1>
          <hr className="bg-persian-green bg-gradient-l-ronchi h-1 border-0 w-1/5 my-6" />
          <p className="font-light leading-relaxed mb-4">
           Die deutsche Startup-Szene ist immer noch männlich dominiert. Laut des Female Founders Monitors (BVDS)
           wurden 2019 19,7 % aller neu gegründeten Start-ups von gemischten Teams gegründet, nur 9,9 % wiederum
           von weiblichen Gründungsteams. Daher ermutigen wir gezielt Frauen, gemeinsam zu gründen. Denn innovative
           und nachhaltige Start-up-Gründungen sind oft das Ergebnis von starken Gründungsteams, in denen Kompetenzen
           und Stärken vereint werden. Genauso wichtig ist es uns, männlich-dominierte Gründungsteams, die an den Impact
           und die Innovationsfähigkeit von diversen Team glauben, zu helfen, bisherige Rekrutierungswege zu erweitern.
          </p><br />
        <Link to="/about" className="inline-block hover:opacity-75 px-6 py-2 rounded text-white bg-persian-green bg-gradient-l-ronchi">
          Mehr über uns
        </Link>
        </div>
      </div>
    </div>
  </div>
)

Section4.propTypes = {
}

Section4.defaultProps = {
}

export default Section4
