import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import groupIcon from '../icons/vermittlung_icon.svg'
import eventIcon from '../icons/event_icon.svg'

const Section1 = ({}) => (
  <section className="container py-10 md:py-26 lg:py-24 xl2:py-40">
    <div className="text-sm text-gradient-green uppercase font-display font-bold">Network</div>
    <h2 className="text-2xl lg:text-4xl leading-none font-display font-bold">B2B Services</h2>
    <hr className="bg-persian-green bg-gradient-l-ronchi h-1 border-0 w-1/5 my-6" />
    <div className="lg:flex lg:pt-8">
      <div className="lg:w-4/12 lg:mr-12 mt-16 lg:mt-0 text-center">
        <img src={groupIcon} alt="Vermittlung" className="h-16 mx-auto mb-6" />
        <h3 className="text-xl lg:text-2xl leading-none font-bold mb-4">Female Co-Founder Vermittlung</h3>
        <p>
          Rekrutierungspool erweitern: Sie suchen nach Mitgründer*innen für eines ihrer Gründungsteams?
          Ihnen ist es wichtig, mehr weiblichen Kandidatinnen zu sprechen und nicht ausschließlich Bewerbungen
          von Männer zu erhalten? Female Co-Founders kann Ihnen gezielt passende Vorschläge an potentiellen
          Mitgründerinnen für Ihre zu besetzenden Co-Founder-Positionen machen.
        </p><br />
        <a href="mailto:hallo@femalecofounders.de?subject=Rekrutierungsanfrage" className="inline-block hover:opacity-75 px-6 py-2 rounded text-white bg-persian-green bg-gradient-l-ronchi">
          Rekrutierungsanfrage
        </a>
      </div>
      <div className="lg:w-4/12 lg:mr-12 mt-16 lg:mt-0 text-center">
        <img src={eventIcon} alt="Events" className="h-16 mx-auto mb-6" />
        <h3 className="text-xl lg:text-2xl leading-none font-bold mb-4">Events in Kooperation</h3>
        <p>
          Akteure auf Augenhöhe: Lassen Sie uns gemeinsam eine Pitch Night in ihrer Region realisieren!
          Sie kennen die lokalen Gegebenheiten und Bedürfnisse und Female Co-Founders bringt ein starkes
          Netzwerk und Expertise in der Vernetzung von Frauen mit Gründungsabsichten mit. Durch ein gemeinsames
          Event erhalten Sie Zugang zur einer Community von Frauen mit Gründungsspirit und gestalten die
          inhaltliche Ausrichtung des Events.
        </p><br />
        <a href="mailto:hallo@femalecofounders.de?subject=Eventanfrage" className="inline-block hover:opacity-75 px-6 py-2 rounded text-white bg-persian-green bg-gradient-l-ronchi">
          Eventanfrage
        </a>
      </div>
      <div className="lg:w-4/12 mt-16 lg:mt-0 text-center">
        <img src={groupIcon} alt="Vermittlung" className="h-16 mx-auto mb-6" />
        <h3 className="text-xl lg:text-2xl leading-none font-bold mb-4">Female Co-Founder Beratung</h3>
        <p>
         Um das Startup-Ökosystem weiblicher und diverser zu gestalten, bedarf es an Angeboten,
         die die besonderen Bedürfnisse unterrepräsentierter Gruppen berücksichtigen. Wir beraten Sie
          gern bei der Gestaltung von Einzelevents, Eventreihen, Kampagnen und Bildungsprogrammen zur
          Gründungssensibilisierung von Frauen und deren Vernetzung.
        </p><br />
        <a href="mailto:hallo@femalecofounders.de?subject=Beratungsanfrage" className="inline-block hover:opacity-75 px-6 py-2 rounded text-white bg-persian-green bg-gradient-l-ronchi">
          Beratungsanfrage
        </a>
      </div>
    </div>
  </section>
)

Section1.propTypes = {
}

Section1.defaultProps = {
}

export default Section1
