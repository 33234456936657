import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import logo1 from '../icons/b2b/unicorn.png'
import logo2 from '../icons/b2b/grace.png'
import logo3 from '../icons/b2b/f10.png'
import logo4 from '../icons/b2b/vv.png'
import logo5 from '../icons/b2b/mfs.png'
import logo6 from '../icons/b2b/soul_rebel.png'
import logo7 from '../icons/b2b/womenventures.png'

const numberStyle = { color: '#EFC7C1', zIndex: '-1', fontSize: '13em' };

const Section2 = ({}) => (
  <div className="relative mb-16">
    <div className="rotated-bg absolute bg-swans-down"></div>
    <section className="container py-12 md:py-20 lg:py-32 lg:pb-20 xl:py-40 xl:pb-24 xl2:py-40 xl2:pb-24 text-center">
      <h2 className="text-2xl lg:text-4xl leading-none font-display font-bold">Partnernetzwerk</h2>
      <hr className="bg-persian-green bg-gradient-l-ronchi h-1 border-0 w-1/12 my-6 mx-auto" />
      <div className="lg:flex flex-col lg:justify-between items-center lg:flex-row mt-16 pb-8">
        <img src={logo1} className="h-12 inline-block my-4 mx-6 lg:m-0" />
        <img src={logo2} className="h-12 inline-block my-4 mx-6 lg:m-0" />
        <img src={logo3} className="h-16 inline-block my-4 mx-6 lg:m-0" />
        <img src={logo4} className="h-12 inline-block my-4 mx-6 lg:m-0" />
        <img src={logo5} className="h-12 inline-block my-4 mx-6 lg:m-0" />
        <img src={logo6} className="h-10 inline-block my-4 mx-6 lg:m-0" />
        <img src={logo7} className="h-12 inline-block my-4 mx-6 lg:m-0" />
      </div>
    </section>
  </div>
)

Section2.propTypes = {
}

Section2.defaultProps = {
}

export default Section2
