import React from "react"

import Layout from "../components/layout"
import Navigation from "../components/navigation"
import Header from "../components/b2b/header"
import Section1 from "../components/b2b/section1"
import Section2 from "../components/b2b/section2"
import Section3 from "../components/b2b/section3"
import Section4 from "../components/b2b/section4"
import SEO from "../components/seo"

const B2bPage = () => (
  <Layout green>
    <SEO title="B2B" />
    <Navigation isAbsolute={false} currentPage="b2b" inverted green />
    <Header />
    <Section1 />
    <Section2 />
    <Section3 />
    <Section4 />
  </Layout>
)

export default B2bPage
