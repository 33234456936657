import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import mobilePhoto from '../photos/b2b_mobile_header.png'
import photo from '../photos/b2b_header.png'

const Header = ({}) => (
  <div className="relative mb-16 bg-pearl-lusta" style={{ zIndex: -5 }}>
    <div className="rotated-bg absolute bg-pearl-lusta">
    </div>

    <div className="md:hidden">
      <img src={mobilePhoto} />
    </div>

    <div className="fc-header-wrapper b2b hidden md:block">
      <div className="fc-header b2b" style={{ backgroundImage: `url(${photo})` }}></div>
    </div>

    <div className="container py-8 md:py-12 xl:py-20 xl2:py-42 xl:pb-6 xl2:pb-8">
      <div className="md:w-7/12">
        <div className="lg:text-lg tracking-wider text-gray-500 uppercase font-display font-bold mb-1 md:mb-4">B2B</div>
        <h1 className="text-4xl lg:text-6xl leading-none font-display font-bold text-gradient-green">Angebot für Unternehmen</h1>
        <hr className="bg-persian-green bg-gradient-l-ronchi h-1 border-0 w-1/5 my-6" />
        <p className="font-light leading-relaxed">
          Sie sind als Company Builder auf der Suche nach Mitgründerinnen für ihre Startups? Sie wollen 
          als Wirtschaftsförderung die weibliche Gründungs- und Nachfolgeszene in ihrer Region stärken? 
          Sie möchten als Akteur der hiesigen Gründungslandschaft ein Programm für unterrepräsentierte Gruppen 
          entwickeln? Profitieren Sie von unserem Netzwerk und unseren Erfahrungen!
        </p>
      </div>
    </div>
  </div>
)

Header.propTypes = {
}

Header.defaultProps = {
}

export default Header
